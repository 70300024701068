import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="organizer"
export default class extends Controller {
  connect() {
    // app/assets/javascripts/comments.js
    $('#organizer_org_setting_attributes_working_days').select2()
    $(".show-nested-comments").on("click", function(event) {
      event.preventDefault();

      const commentId = $(this).data("commentId");
      const nestedComments = $("#nested-comments-" + commentId);

      if (nestedComments.is(":hidden")) {
        nestedComments.show();
        $(this).text("Hide nested comments");
      } else {
        nestedComments.hide();
        $(this).text("Show nested comments");
      }
    });

    document.addEventListener('DOMContentLoaded', () => {
      const passwordField = document.getElementById('login-password');
      const togglePasswordBtn = document.getElementById('toggle-password');

      togglePasswordBtn.addEventListener('click', () => {
        const isPasswordVisible = passwordField.type === 'text';
        passwordField.type = isPasswordVisible ? 'password' : 'text';
        togglePasswordBtn.innerHTML = isPasswordVisible ? '<i class="bi bi-eye-slash"></i>' : '<i class="bi bi-eye"></i>';
      });
    });

    $('#org_setting_apply_duration, #org_setting_refere_period').on('keydown', function(e) {
      if ( e.key == '-' || e.key == '+' ) {
        e.preventDefault();
      }
    });

    $('#org_setting_refere_period, #org_setting_apply_duration').on('keydown', function(event) {
      let inputValue = $(this).val() || '';
      let parts = inputValue.split('.');
      let decimalPart = parts[1] || '';
      let allowedKeys = ['Backspace', 'Delete', '.']

      if ((decimalPart.length >= 2 ) && !allowedKeys.includes(event.key)){
        event.preventDefault();
      }

    });

    validatorBasic()
    customSelect2('#organization_size', true);
    customSelect2('#country', true);
    customSelect2('#user_gender', true);
    customSelect2('#user_role_id', true);
    customSelect2('#user_country', true);
    customSelect2('#organizer_organization_size', true);
    customSelect2('#org_setting_referal_policy_id', true);
    customSelect2('#country_code', true);
    customSelect2('#organizer_country_code', true);
    
    $('.SmtpSettingFrom').validate({
       rules: {
        "setting_smtp[username]": {
          emailregex: true
        },
        "setting_smtp[password]":{
          passwordregex: true,
          minlength: 8,
          maxlength: 15
        }
      },
      messages: {
        "setting_smtp[username]":{
          emailregex: 'Please enter a valid email address'
        },
        "setting_smtp[password]":{
          minlength: 'Password must be 8-15 characters long',
          maxlength: 'Password must be 8-15 characters long'
        }
      }
    })

    $('.user_validation').validate({
      rules: {
        "user[first_name]": {
          required: true,
          nameregex: true,
          minlength: 2,
          maxlength: 25
        },
        "user[last_name]": {
          required: true,
          nameregex: true,
          minlength: 2,
          maxlength: 25
        },
        "user[role_id]": {
          required: true
        },
        "user[email]": {
          required: true,
          emailregex: true
        },
        "user[contact_number]": {
          phoneregex: true,
          minlength: 10,
          maxlength: 10
        },
        "user[password]": {
          // required: true,
          passwordregex: true,
          minlength: 6,
          maxlength: 18
        },
        "user[password_confirmation]": {
          // required: true,
          userpasswordmatch: true
        }
      },
      messages: {
        "user[first_name]": {
          required: 'This is a required field.',
          nameregex: 'The first name can only consist of alphabets.',
          minlength: 'Must be 2-25 characters long',
          maxlength: 'Must be 2-25 characters long'
        },
        "user[role_id]": {
          required: 'This is a required field.',
          nameregex: 'The last name can only consist of alphabets.',
          minlength: 'Must be 2-25 characters long',
          maxlength: 'Must be 2-25 characters long'
        },
        "user[last_name]": {
          required: 'This is a required field.',
        },
        "user[contact_number]": {
          phoneregex: 'Please enter a valid number',
          maxlength: 'Mobile number must have 10 digit'
        },
        "user[email]": {
          required: 'This is a required field.',
          emailregex: 'Please enter a valid email address.'
        },
        "user[password]": {
          // required: 'This is a required field.',
          minlength: 'Must be 6-18 characters long',
          maxlength: 'Must be 6-18 characters long'
        },
        "user[password_confirmation]": {
          // required: 'This is a required field.',
          userpasswordmatch: 'Must be match with password.'
        }
      }
    })

    $('.settingFrom').validate({
      rules: {
        "organizer[name]": {
          required: true,
          nameregex: true,
          minlength: 3,
          maxlength: 50
        },
        "organizer[phone]": {
          required: true,
          phoneregex: true,
          minlength: 10,
          maxlength: 10
        },
        "organizer[organization_size]": {
          required: true
        }
      },
      messages: {
        "organizer[name]": {
          required: 'This is a required field.',
          nameregex: 'The company name can only consist of alphabets.',
        },
        "organizer[phone]": {
          required: 'This is a required field.',
          phoneregex: "Only includes digits with country code."
        },
        "organizer[organization_size]": {
          required: 'This is a required field.'
        }
      }
    })

    $('#organizer-sign-in').validate({
      rules: {
        "organizer": {
          required: true,
        },
        "email": {
          required: true,
          emailregex: true
        }
      },
      messages: {
        "organizer": {
          required: 'This is a required field.',
        },
        "email": {
          required: 'This is a required field.',
          emailregex: "Please enter a valid email address."
        }
      }
    })

    $('#new_user').validate({
      rules: {
        "user[email]": {
          required: true,
          emailregex: true
        },
        "user[password]": {
          required: true,        }
      },
      messages: {
        "user[email]": {
          required: 'Please enter email Address',
          emailregex: "Please enter valid email Address"
        },
        "user[password]": {
          required: 'Please enter password'
        }
      }
    })

    $('.referralSettingForm').validate({
      rules: {
        "org_setting[refere_period]": {
          required: true,
          max: 99.99,
          min: 0,
        },
        "org_setting[apply_duration]": {
          required: true,
          max: 99.99,
          min: 0,
        }
      },
      messages: {
        "org_setting[refere_period]": {
          required: 'This field is required.',
          max: 'Please enter valid value.',
          min: 'Please enter valid value.'
        },
        "org_setting[apply_duration]": {
          required: 'This field is required.',
          max: 'Please enter valid value.',
          min: 'Please enter valid value.'
        }
      }
    })
    $('#forgot-password-page').validate({
      rules: {
        "user[email]": {
          required: true,
          emailregex: true
        },
        "employee[email]": {
          required: true,
          emailregex: true
        }
      },
      messages: {
        "user[email]": {
          required: 'This is a required field.',
          emailregex: "Please enter a valid email address."
        },
        "employee[email]": {
          required: 'This is a required field.',
          emailregex: "Please enter a valid email address."
        }
      }
    })

    $('.separationFrom').validate({
      rules: {
        "org_setting[separation_visibility]": {
          required: true,
          min: 0,
          max: 99,
        },
        "org_setting[separation_period]": {
          required: true,
          min: 0,
          max: 999,
        }
      },
      messages: {
        "org_setting[separation_visibility]": {
          required: 'This is a required field.',
        },
        "org_setting[separation_period]": {
          required: 'This is a required field.',
        }
      }
    })

    $.validator.addMethod('nameregex', function(value, element){
      return this.optional(element) || /^[a-zA-Z]+(?:\s[a-zA-Z]+)*(?:\s?)$/.test(value);
    },'Contains alphabets only');

    $.validator.addMethod('phoneregex', function(value, element){
      return this.optional(element) || /^[0-9]+$/.test(value);
    },'Contains digits only');

    $.validator.addMethod('emailregex', function(value, element){
      return this.optional(element) || /^[a-zA-Z0-9]+(?:[._%+()-][a-zA-Z0-9]+)*@[a-zA-Z0-9]+\.[a-zA-Z]{2,}$/g.test(value);
    });

    $.validator.addMethod('passwordmatch', function(value, element){
      var password = $("[name='password']").val()
      return this.optional(element) || password == value;
    });

    $.validator.addMethod('userpasswordmatch', function(value, element){
      var password = $("[name='user[password]']").val()
      return this.optional(element) || password == value;
    });

    $.validator.addMethod('passwordregex', function(value, element){
      return this.optional(element) || /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^\w\s]).+$/.test(value);
    },'Password must contains one uppercase, lowercase, number and special character');
  }
}
