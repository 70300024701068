import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="custom-alert"
export default class extends Controller {
  connect() {
    $(document).on('click', '.swal-confirm', function(event) {
      var $link = $(this);
      event.preventDefault();

      var iconType = $link.data('icon') || 'warning';
      var confirmMessage = $link.data('confirm-message') || 'Are you sure?';
      var confirmText = $link.data('confirm-text') || 'OK';
      var cancelText = $link.data('cancel-text') || 'Cancel';
      var htmlContent = $link.data('icon') == 'warning2' ? '<img src="https://img.icons8.com/?size=256&id=1lpBESDEGeuX&format=png" class="swal2-custom-icon"/>' : '';
      htmlContent = htmlContent + `<div class="swal2-html-container">${confirmMessage}</div>`;

      // SweetAlert confirmation dialog
      Swal.fire({
        icon: iconType,
        html: htmlContent,
        showCancelButton: true,
        confirmButtonColor: '#d11a2a',
        confirmButtonText: confirmText,
        cancelButtonText: cancelText
      }).then(function(result) {
        if (result.isConfirmed) {
          if ($link.is('a')) {
            window.location.href = $link.attr('href'); // Proceed to the link's URL
          } else if ($link.closest('form').length) {
            $link.closest('form').submit(); // Submit the form
          }
        }
      });
    });
  }

}
